import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMixpanel } from "../context/MixpanelContext";
import { usePlausible } from "../context/PlausibleContext";
import i18n from "../i18n";

export function Page({ children }: any) {
  const { pathname } = useLocation();

  useEffect(() => {
    mixpanel.track("Opened page", {
      path: pathname,
      app_language: i18n.resolvedLanguage,
    });
  }, [pathname]);

  return (
    <div className="bg-zinc-100 min-h-full w-full flex justify-center">
      <div className="w-full max-w-xl">{children}</div>
    </div>
  );
}

import { Pause, Play } from "phosphor-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { PlayerData, useAudioPlayer } from "../context/AudioContext";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

interface AudioPlayerInlineProps {
  coverImage: string;
  audio?: string;
  customDuration?: number;
  onclick?: () => void;
  data: PlayerData;
  onEnded?: () => void;
  enableFeedback?: boolean;
}

export function AudioPlayerInline({
  coverImage,
  audio,
  customDuration,
  onclick,
  data,
  onEnded,
  enableFeedback = true
}: AudioPlayerInlineProps) {
  const {
    url: PlayerURL,
    playing,
    setUrl,
    play,
    pause,
    setData,
    addProgressListener,
    removeProgressListener,
    seekTo,
  } = useAudioPlayer();

  const isPlaying = (url: string) => {
    return url === PlayerURL && playing;
  };

  const firstTimeFeedbackOpen = useRef(true);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const progressBar = useRef<HTMLDivElement | null>(null);
  const playedSecondsElementRef = useRef<HTMLSpanElement | null>(null);
  const playedSecondsRef = useRef(0);

  const playAudio = (url: string, data: PlayerData) => {
    setUrl(url);
    setData(data);
    seekTo(playedSecondsRef.current);
    play();
  };

  const pauseAudio = () => {
    pause();
    if (firstTimeFeedbackOpen && enableFeedback) {
      setFeedbackOpen(true);
      firstTimeFeedbackOpen.current = false;
    }
  };

  const onProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
    url: string | undefined;
  }) => {
    if (state.url === audio) {
      if (progressBar.current) {
        progressBar.current.style.width = `${state.played * 100}%`;
      }
      if (playedSecondsElementRef.current) {
        playedSecondsElementRef.current.innerText = formatSeconds(
          state.playedSeconds
        );
      }
      playedSecondsRef.current = state.playedSeconds;
      if (state.played > 0.5 && firstTimeFeedbackOpen.current && enableFeedback) {
        setFeedbackOpen(true);
        firstTimeFeedbackOpen.current = false;
      }
      if (state.played >= 1.0) {
        onEnded?.();
        pause();
        resetProgressBar();
      }
    }
  };

  const resetProgressBar = () => {
    if (progressBar.current) {
      progressBar.current.style.width = "0%";
    }
    if (playedSecondsElementRef.current) {
      playedSecondsElementRef.current.innerText = formatSeconds(0);
    }
    playedSecondsRef.current = 0;
    setFeedbackOpen(false)
    firstTimeFeedbackOpen.current = true;
  };

  const [duration, setDuration] = useState<number>(0);

  const getDuration = () => {
    if (audio != null) {
      const audioInstance = new Audio();
      audioInstance.addEventListener("loadedmetadata", () => {
        setDuration(audioInstance.duration);
      });
      audioInstance.src = audio;
    } else {
      if (customDuration != null) {
        setDuration(customDuration)
      }
    }
  };

  useEffect(() => {
    addProgressListener(onProgress);
    getDuration();
    return () => {
      removeProgressListener(onProgress)
      resetProgressBar();
    }
  }, [audio]);

  const formatSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const restSeconds = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${restSeconds}`;
  };

  const { t } = useTranslation();

  return (
    <div>
      <div
        onClick={() => {
          if (audio != null) {
            isPlaying(audio) ? pauseAudio() : playAudio(audio, data);
          }
          onclick?.();
        }}
        className="relative z-10 w-full rounded-2xl bg-white p-3 pr-4 mt-5 flex flex-col items-center cursor-pointer"
      >
        <div className="w-full flex items-center">
          <div
            className="w-16 h-16 rounded-xl bg-cover bg-center"
            style={{
              backgroundImage: `url(${coverImage})`,
            }}
          />
          <div className="ml-3 flex-1">
            <h4 className="font-semibold text-sm leading-tight">
              {data.title}
            </h4>
            <h5 className="mt-0.5 font-medium text-xs text-gray-500">
              {data.subline}
            </h5>
          </div>
          <div className="rounded-full bg-cyan-500 shadow-md w-12 h-12 flex justify-center items-center p-4">
            {audio != null && isPlaying(audio) ? (
              <Pause className="w-full h-full" color="white" weight="fill" />
            ) : (
              <Play className="w-full h-full" color="white" weight="fill" />
            )}
          </div>
        </div>
        <div className="mt-3 w-full flex justify-center items-center space-x-3">
          <span
            ref={playedSecondsElementRef}
            className="text-xs text-gray-400 font-medium w-6"
          >
            0:00
          </span>
          <div className="bg-gray-200 w-full flex-1 rounded-full h-1.5 overflow-hidden">
            <div
              ref={progressBar}
              className="w-0 bg-cyan-500 h-full transition-all duration-1000"
            ></div>
          </div>
          <span className="text-xs text-gray-400 font-medium w-6">
            {formatSeconds(duration)}
          </span>
        </div>
      </div>
      <div
        className={classNames(
          "relative z-0 px-2 transition-all duration-700 delay-100",
          {
            "-mt-32 opacity-0": !feedbackOpen,
            "mt-0 opacity-100": feedbackOpen,
          }
        )}
      >
        <div className="flex flex-col bg-gray-200 border border-gray-300 shadow-sm p-3 rounded-2xl pt-7 -mt-5">
          <h3 className="font-medium text-sm text-gray-600">
            {t("AUDIO_GUIDE_FEEDBACK_TITLE")}
          </h3>
          <div className="mt-2 mx-auto px-2 flex space-x-1 xs:space-x-3 xs:px-4">
            {[...Array(5)].map((e, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col items-center space-y-1 w-10"
                >
                  <button
                    onClick={() => {
                      setFeedbackOpen(false);
                      mixpanel.track("Audio Guide Feedback", {
                        id: data.id,
                        rating: i + 1,
                      });
                    }}
                    className="w-10 h-10 bg-white/90 text-gray-600 border border-gray-400 flex justify-center items-center rounded-lg"
                  >
                    {i + 1}
                  </button>
                  {i == 0 && (
                    <span className="text-xs text-gray-600 whitespace-nowrap">
                      {t("AUDIO_GUIDE_FEEDBACK_1")}
                    </span>
                  )}
                  {i == 4 && (
                    <span className="text-xs text-gray-600  whitespace-nowrap">
                      {t("AUDIO_GUIDE_FEEDBACK_5")}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

import { Card } from "../components/Card";
import { Page } from "../components/Page";
import { Searchbar } from "../components/Searchbar";
import { Padding } from "../components/Padding";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Attraction, data } from "../assets/data";
import classNames from "classnames";
import EastSideGalleryImage from "../assets/images/east-side-gallery.jpg";
import ESGIntroImage from "../assets/images/esg-intro.jpeg";
import ARPreviewImage from "../assets/images/ar_preview.gif";
import LinkedInImage from "../assets/images/LinkedIn.png";
import InstagramImage from "../assets/images/Instagram.png";
import TTBImage from "../assets/images/ttb.jpeg";
import GermanyFlag from "../assets/images/flags/germany-flag.png";
import UKFlag from "../assets/images/flags/uk-flag.png";
import KissImage from "../assets/images/kiss.jpg";
import KaniAlaviImage from "../assets/images/kani-alavi.jpg";
import VolkImage from "../assets/images/volk.jpg";
import ThierryNoirImage from "../assets/images/thierry-noir.jpg";
import {
  ArrowRight,
  CaretRight,
  Lock,
  LockKey,
  LockKeyOpen,
  Pause,
  Play,
  X,
} from "phosphor-react";
import { TextCollapse } from "../components/TextCollapse";
import { ARGlyph } from "../components/ARGlyph";
import { PlayerData, useAudioPlayer } from "../context/AudioContext";

import ESGProsaDE from "../assets/audio/esg_prosa_de.mp3";
import ESGProsaEN from "../assets/audio/esg_prosa_en.mp3";
import ESGDialogDE from "../assets/audio/esg_dialog_de.mp3";
import ESGDialogDEPreview from "../assets/audio/esg_dialog_de_preview.mp3";
import ESGDialogEN from "../assets/audio/esg_dialog_en.mp3";
import ESGDialogENPreview from "../assets/audio/esg_dialog_en_preview.mp3";
import TestTheBestProsaAudioDE from "../assets/audio/test-the-best_prosa_de.mp3";
import TestTheBestProsaAudioEN from "../assets/audio/test-the-best_prosa_en.mp3";
import TestTheBestDialogAudioDE from "../assets/audio/test-the-best_dialog_de.mp3";
import TestTheBestDialogAudioEN from "../assets/audio/test-the-best_dialog_en.mp3";

import { useEffect, useRef, useState } from "react";
import { LanguageSelectorPopup } from "../components/LanguageSelectorPopup";
import { MoreToursPopup } from "../components/MoreToursPopup";
import mixpanel from "mixpanel-browser";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { NewsletterSignup } from "../components/NewsletterSignup";
import { Trans, useTranslation } from "react-i18next";
import { AudioPlayerInline } from "../components/AudioPlayerInline";
import { PayPopup } from "../components/PayPopup";
import { useStore } from "../state";
import { InProductionPopup } from "../components/InProductionPopup";

function getRandomABTest(): "A" | "B" {
  const rand = Math.random();
  if (rand > 0.5) {
    return "A";
  } else {
    return "B";
  }
}

export function ESG() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const ABTest = "A";

  const [unlocked, setUnlocked] = useLocalStorage<boolean>("unlocked", false);

  useEffect(() => {
    mixpanel.register({
      unlocked: unlocked,
    });
  }, [unlocked]);

  useEffect(() => {
    const unlocked = searchParams.get("unlocked");
    if (unlocked === "true") {
      setUnlocked(true);
    }
    if (unlocked === "false") {
      setUnlocked(false);
    }
  }, [searchParams]);

  const { t, i18n } = useTranslation();

  const ESGIntroDialog = {
    id: "ESG Intro [Dialog]",
    audio:
      i18n.resolvedLanguage === "de"
        ? unlocked
          ? ESGDialogDE
          : ESGDialogDEPreview
        : unlocked
        ? ESGDialogEN
        : ESGDialogENPreview,
    title: t("ESG_OVERVIEW_AUDIO_TITLE"),
    subline: t("ESG_OVERVIEW_AUDIO_SUBLINE"),
    image: ESGIntroImage,
  };

  const ESGIntroProsa = {
    id: "ESG Intro [Prosa]",
    audio: i18n.resolvedLanguage === "de" ? ESGProsaDE : ESGProsaEN,
    title: t("ESG_OVERVIEW_AUDIO_TITLE"),
    subline: t("ESG_OVERVIEW_AUDIO_SUBLINE"),
    image: ESGIntroImage,
  };

  const TestTheBestDialog = {
    id: "TEST THE BEST - Birgit Kinder [DIALOG]",
    audio:
      i18n.resolvedLanguage === "de"
        ? TestTheBestDialogAudioDE
        : TestTheBestDialogAudioEN,
    title: t("ESG_TEST_THE_BEST_AUDIO_TITLE"),
    subline: t("ESG_TEST_THE_BEST_AUDIO_SUBLINE"),
    image: TTBImage,
  };

  const TestTheBestProsa = {
    id: "TEST THE BEST - Birgit Kinder [Prosa]",
    audio:
      i18n.resolvedLanguage === "de"
        ? TestTheBestProsaAudioDE
        : TestTheBestProsaAudioEN,
    title: t("ESG_TEST_THE_BEST_AUDIO_TITLE"),
    subline: t("ESG_TEST_THE_BEST_AUDIO_SUBLINE"),
    image: TTBImage,
  };

  const audioGuides = {
    intro: {
      A: ESGIntroDialog,
      B: ESGIntroProsa,
    },
    testTheBest: {
      A: TestTheBestDialog,
      B: TestTheBestProsa,
    },
  };

  const introGuide = audioGuides.intro[ABTest];
  const testTheBestGuide = audioGuides.testTheBest[ABTest];

  const {
    url: PlayerURL,
    playing,
    setUrl,
    play,
    pause,
    data,
    setData,
    seekTo,
  } = useAudioPlayer();

  useEffect(() => {
    setUrl(ESGIntroDialog.audio);
  }, []);

  const playAudio = (url: string, data: PlayerData) => {
    setUrl(url);
    setData(data);
    play();
  };

  const pauseAudio = () => {
    pause();
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (!isFirstRun.current) {
      pause();
      setUrl(undefined);
    } else {
      isFirstRun.current = false;
    }
  }, [i18n.resolvedLanguage]);

  const isPlaying = (url: string) => {
    return url === PlayerURL && playing;
  };

  useEffect(() => {
    if (PlayerURL === introGuide.audio) {
      setData({
        ...introGuide,
      });
    }
  }, [introGuide, PlayerURL]);

  useEffect(() => {
    if (PlayerURL === testTheBestGuide.audio) {
      setData({
        ...testTheBestGuide,
      });
    }
  }, [testTheBestGuide, PlayerURL]);

  const [popup, setPopup] = useState<string | null>(null);

  const { payPopupOpen, setPayPopupOpen } = useStore();

  const openPayPopup = () => {
    if (!unlocked) {
      setPayPopupOpen(true);
    }
  };

  const unlockContent = () => {
    setUnlocked(true);
  };

  const testTheBestAR = (
    <div
      onClick={() => {
        if (unlocked) {
          navigate("ar-test-the-best");
          mixpanel.track("Clicked 'Augmented Reality'");
        } else {
          setPayPopupOpen(true);
        }
      }}
      className="w-full relative rounded-2xl bg-cyan-500 p-2 pr-4 mt-8 flex items-center cursor-pointer"
    >
      <div
        className="w-16 h-16 rounded-xl bg-cover bg-center"
        style={{
          backgroundImage: `url(${ARPreviewImage})`,
        }}
      />
      <div className="ml-2 flex-1">
        <h4 className="text-white font-normal text-md leading-tight">
          <Trans i18nKey="ESG_TEST_THE_BEST_AR_HEADLINE">
            Erlebe <span className="font-medium">TEST THE BEST</span> in 3D
          </Trans>
        </h4>
        <h5 className="font-semibold text-sm text-white">
          {t("ESG_TEST_THE_BEST_AR_SUBLINE")}
        </h5>
      </div>
      {!unlocked && (
        <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
          <LockKey className="text-white h-full w-full" />
        </div>
      )}
      {unlocked && (
        <div className="absolute -top-6 -right-2 animate-pop rounded-full bg-white shadow-md w-12 h-12 flex justify-center items-center p-2.5">
          <ARGlyph className="text-cyan-500 h-10" />
        </div>
      )}
    </div>
  );

  return (
    <Page>
      {payPopupOpen && (
        <PayPopup
          onClose={() => setPayPopupOpen(false)}
          onUnlock={unlockContent}
        />
      )}

      {popup != null && (
        <div className="fixed inset-0 backdrop-blur-md bg-black/80 z-50">
          <div className="w-full max-w-xl mx-auto relative">
            <h1 className="absolute top-4 left-6 text-white text-xl text-center">
              <span className="font-semibold">one</span>Guide
            </h1>
            <div
              onClick={() => setPopup(null)}
              className="absolute top-4 right-6 w-6 h-6 cursor-pointer"
            >
              <X className="w-full h-full" color="white" />
            </div>
            {popup === "language" && (
              <LanguageSelectorPopup onClose={() => setPopup(null)} />
            )}
            {popup === "more-tours" && <MoreToursPopup />}
            {popup === "in-production" && <InProductionPopup />}
          </div>
        </div>
      )}
      <div className="bg-black w-full h-24 flex flex-col justify-center items-center">
        <h1 className="text-white text-3xl text-center">
          <span className="font-semibold">one</span>Guide
        </h1>
        <h2 className="text-white text-sm">audio & augmented reality tours</h2>
      </div>
      <div className="h-64 w-full relative">
        <div className="absolute inset-0 z-20 flex flex-col justify-center items-center px-4">
          <h1 className="text-white font-bold text-3xl text-center">
            EAST SIDE GALLERY
          </h1>
          <h2 className="text-white font-normal text-2xl">BERLIN</h2>
        </div>
        <div
          onClick={() => {
            playing
              ? pauseAudio()
              : data != null
              ? play()
              : playAudio(introGuide.audio, {
                  id: introGuide.id,
                  title: introGuide.title,
                  subline: introGuide.subline,
                  image: introGuide.image,
                });
          }}
          className="absolute -bottom-8 left-8 rounded-full bg-cyan-500 shadow-lg w-20 h-20 flex justify-center items-center p-6 -mt-10 z-20 cursor-pointer hover:scale-105 transition-transform"
        >
          {playing ? (
            <Pause className="w-full h-full" color="white" weight="fill" />
          ) : (
            <Play className="w-full h-full" color="white" weight="fill" />
          )}
        </div>
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -inset-10 z-10 backdrop-blur-sm bg-black/20" />
        </div>
        <div
          className="w-full h-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${EastSideGalleryImage})`,
          }}
        />
      </div>

      <Padding>
        <div className="flex justify-end mt-4">
          <div
            onClick={() => {
              setPopup("language");
              mixpanel.track("Clicked 'Change Language'");
            }}
            className="bg-gray-200 hover:bg-gray-200/70 border border-gray-300 rounded-md px-3 py-2 flex flex-row items-center cursor-pointer"
          >
            {i18n.resolvedLanguage === "de" && (
              <img
                className=" h-4 mr-2"
                src={GermanyFlag}
                alt="Deutschland Flagge"
              />
            )}
            {i18n.resolvedLanguage === "en" && (
              <img className=" h-4 mr-2" src={UKFlag} alt="UK Flag" />
            )}
            <span className="font-semibold text-xs whitespace-nowrap">
              Change Language
            </span>
          </div>
        </div>
      </Padding>

      <Padding>
        <div className="mt-5"></div>
        {!unlocked && (
          <h3 className="text-gray-400 text-sm">{t("PREVIEW_INFO")}</h3>
        )}
        <h1 className="text-xl  font-semibold">
          {t("ESG_OVERVIEW_SECTION_TITLE")}
        </h1>
        <div className="relative">
          <AudioPlayerInline
            coverImage={ESGIntroImage}
            audio={introGuide.audio}
            data={{
              id: introGuide.id,
              title: introGuide.title,
              subline: introGuide.subline,
              image: introGuide.image,
            }}
            enableFeedback={unlocked}
            onEnded={() => {
              if (!unlocked) {
                setPayPopupOpen(true);
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "ESG - Intro",
              });
            }}
            preview={t("ESG_OVERVIEW_TEXT_EXCERPT")}
          >
            {t("ESG_OVERVIEW_TEXT")}
          </TextCollapse>
        </p>
      </Padding>

      {!unlocked && (
        <Padding>
          <div className="flex items-center justify-center w-full mt-8 -mb-3">
            <div
              onClick={() => {
                setPayPopupOpen(true);
                mixpanel.track("Clicked 'Unlock'");
              }}
              className="bg-emerald-500 text-white font-medium text-md px-4 md:px-14 py-2 rounded-lg shadow-lg cursor-pointer"
            >
              <span className="flex flex-row justify-center items-center uppercase">
                {t("UNLOCK_BUTTON")}{" "}
                <LockKeyOpen
                  className="text-white h-4 w-4 ml-2"
                  weight="bold"
                />
              </span>
            </div>
          </div>
        </Padding>
      )}

      <Padding>
        <h1 className="text-xl mt-8 font-semibold">
          {t("ESG_TEST_THE_BEST_SECTION_TITLE")}
        </h1>

        <div className="relative">
          {!unlocked && (
            <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
              <LockKey className="text-white h-full w-full" />
            </div>
          )}
          <AudioPlayerInline
            coverImage={TTBImage}
            audio={unlocked ? testTheBestGuide.audio : undefined}
            customDuration={207}
            data={{
              id: testTheBestGuide.id,
              title: testTheBestGuide.title,
              subline: testTheBestGuide.subline,
              image: testTheBestGuide.image,
            }}
            onclick={() => {
              if (!unlocked) {
                setPayPopupOpen(true);
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "Test the Best",
              });
            }}
            preview={t("ESG_TEST_THE_BEST_TEXT_EXCERPT")}
          >
            {t("ESG_TEST_THE_BEST_TEXT")}
          </TextCollapse>
        </p>

        {testTheBestAR}
      </Padding>

      <Padding>
        <h1 className="text-xl mt-8 font-semibold">
          {t("ESG_KISS_SECTION_TITLE")}
        </h1>

        <div className="relative">
          {!unlocked && (
            <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
              <LockKey className="text-white h-full w-full" />
            </div>
          )}
          <AudioPlayerInline
            coverImage={KissImage}
            customDuration={268}
            data={{
              id: "Kiss",
              title: t("ESG_KISS_TITLE"),
              subline: t("ESG_KISS_SUBLINE"),
              image: KissImage,
            }}
            onclick={() => {
              mixpanel.track("Clicked 'In production'", {
                id: "Kiss",
              });
              if (!unlocked) {
                setPayPopupOpen(true);
              } else {
                setPopup("in-production");
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "Kiss",
              });
            }}
            preview={t("ESG_KISS_TEXT_EXCERPT")}
          >
            {t("ESG_KISS_TEXT")}
          </TextCollapse>
        </p>
      </Padding>

      <Padding>
        <h1 className="text-xl mt-8 font-semibold">
          {t("ESG_KANI_SECTION_TITLE")}
        </h1>

        <div className="relative">
          {!unlocked && (
            <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
              <LockKey className="text-white h-full w-full" />
            </div>
          )}
          <AudioPlayerInline
            coverImage={KaniAlaviImage}
            customDuration={147}
            data={{
              id: "Kani Alavi",
              title: t("ESG_KANI_TITLE"),
              subline: t("ESG_KANI_SUBLINE"),
              image: KaniAlaviImage,
            }}
            onclick={() => {
              mixpanel.track("Clicked 'In production'", {
                id: "Kani Alavi",
              });
              if (!unlocked) {
                setPayPopupOpen(true);
              } else {
                setPopup("in-production");
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "Kani",
              });
            }}
            preview={t("ESG_KANI_TEXT_EXCERPT")}
          >
            {t("ESG_KANI_TEXT")}
          </TextCollapse>
        </p>
      </Padding>

      <Padding>
        <h1 className="text-xl mt-8 font-semibold">
          {t("ESG_VOLK_SECTION_TITLE")}
        </h1>

        <div className="relative">
          {!unlocked && (
            <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
              <LockKey className="text-white h-full w-full" />
            </div>
          )}
          <AudioPlayerInline
            coverImage={VolkImage}
            customDuration={169}
            data={{
              id: "Volk",
              title: t("ESG_VOLK_TITLE"),
              subline: t("ESG_VOLK_SUBLINE"),
              image: VolkImage,
            }}
            onclick={() => {
              mixpanel.track("Clicked 'In production'", {
                id: "Volk",
              });
              if (!unlocked) {
                setPayPopupOpen(true);
              } else {
                setPopup("in-production");
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "Volk",
              });
            }}
            preview={t("ESG_VOLK_TEXT_EXCERPT")}
          >
            {t("ESG_VOLK_TEXT")}
          </TextCollapse>
        </p>
      </Padding>

      <Padding>
        <h1 className="text-xl mt-8 font-semibold">
          {t("ESG_NOIR_SECTION_TITLE")}
        </h1>

        <div className="relative">
          {!unlocked && (
            <div className="absolute -top-4 -right-3 rounded-full bg-black shadow-md w-10 h-10 flex justify-center items-center p-2.5 z-20">
              <LockKey className="text-white h-full w-full" />
            </div>
          )}
          <AudioPlayerInline
            coverImage={ThierryNoirImage}
            customDuration={132}
            data={{
              id: "Noir",
              title: t("ESG_NOIR_TITLE"),
              subline: t("ESG_NOIR_SUBLINE"),
              image: ThierryNoirImage,
            }}
            onclick={() => {
              mixpanel.track("Clicked 'In production'", {
                id: "Noir",
              });
              if (!unlocked) {
                setPayPopupOpen(true);
              } else {
                setPopup("in-production");
              }
            }}
          />
        </div>

        <p className="mt-4 font-normal text-md leading-relaxed">
          <TextCollapse
            onShow={() => {
              mixpanel.track("Clicked on 'Weiterlesen'", {
                topic: "Noir",
              });
            }}
            preview={t("ESG_NOIR_TEXT_EXCERPT")}
          >
            {t("ESG_NOIR_TEXT")}
          </TextCollapse>
        </p>
      </Padding>

      <Padding>
        <div className="flex items-center justify-center w-full mt-10">
          <div
            onClick={() => {
              setPopup("more-tours");
              mixpanel.track("Clicked 'More Tours'");
            }}
            className="bg-black text-white font-semibold text-md px-14 py-3 rounded-full shadow-lg cursor-pointer"
          >
            {t("SHOW_MORE_TOURS")}
          </div>
        </div>
      </Padding>

      <Padding>
        <div className="flex justify-center mt-4">
          <hr className="my-8 w-10/12" />
        </div>
      </Padding>

      <Padding>
        <h1 className="text-[1.6rem]">{t("SURVEY_HEADLINE")}</h1>
        <h2 className="mt-1 text-sm font-semibold">{t("SURVEY_SUBLINE")}</h2>

        <div className="flex items-center justify-center w-full mt-6">
          <div
            onClick={() => {
              mixpanel.track("Clicked 'Umfrage starten'");
              const enLink = "https://65oydqmwy11.typeform.com/to/c9Hf98So";
              const deLink = "https://65oydqmwy11.typeform.com/to/TVjXGues";
              const languageLink =
                i18n.resolvedLanguage === "de" ? deLink : enLink;
              const link =
                languageLink +
                `?utm_source=mvp4&#source=mvp4&esg_intro=${encodeURIComponent(
                  introGuide.id
                )}&esg_ttb=${encodeURIComponent(testTheBestGuide.id)}`;
              window.open(link, "_blank")?.focus();
            }}
            className="bg-black text-white font-semibold text-md px-14 py-3 rounded-md shadow-lg flex justify-center items-center cursor-pointer"
          >
            {t("SURVEY_BUTTON_TEXT")}{" "}
            <ArrowRight className="ml-1" weight="bold" />
          </div>
        </div>
      </Padding>

      <Padding>
        <div className="flex justify-center mt-4">
          <hr className="my-8 w-10/12" />
        </div>
      </Padding>

      <Padding>
        <NewsletterSignup />
      </Padding>

      <Padding>
        <div className="flex justify-center mt-4">
          <hr className="my-8 w-10/12" />
        </div>
        <div className="mb-20 flex items-baseline">
          <div className="flex-1 flex space-x-2">
            <a
              href="https://www.instagram.com/_oneguide_"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-7" src={InstagramImage} alt="Instagram Icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/one-guide/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-7" src={LinkedInImage} alt="LinkedIn Icon" />
            </a>
          </div>
          <a href="/impressum">Impressum</a>
        </div>
      </Padding>
    </Page>
  );
}

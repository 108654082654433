import mixpanel from "mixpanel-browser";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const MixpanelContext = createContext<any>(undefined);

function MixpanelProvider({ debug = false, token, children }: any) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    mixpanel.init(token, {
      debug: debug,
      loaded: () => {
        setLoaded(true);
      },
    });
    // mixpanel.disable()
  }, []);

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {loaded && children}
    </MixpanelContext.Provider>
  );
}

function useMixpanel() {
  const context = useContext(MixpanelContext);
  if (context === undefined) {
    throw new Error("useMixpanel must be used within a MixpanelContext");
  }
  return context;
}

export { MixpanelProvider, useMixpanel };

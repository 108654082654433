import create from "zustand";

interface AppState {
  payPopupOpen: boolean;
  setPayPopupOpen: (open: boolean) => void;
}

export const useStore = create<AppState>((set) => ({
  payPopupOpen: false,
  setPayPopupOpen: (open: boolean) => set((state) => ({ payPopupOpen: open })),
}));

import mixpanel from "mixpanel-browser";
import { createContext, useState, useEffect, useContext, useRef } from "react";
import ReactPlayer from "react-player";
import { useStore } from "../state";

interface ContextType {
  url: string | undefined;
  data: PlayerData | undefined;
  playing: boolean;
  play: () => void;
  pause: () => void;
  setUrl: (url: string | undefined) => void;
  setData: (data: PlayerData) => void;
  seekTo: (amount: number, type?: "seconds" | "fraction" | undefined) => void;
  addProgressListener: (
    callback: (state: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
      url: string | undefined;
    }) => void
  ) => void;
  removeProgressListener: (
    callback: (state: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
      url: string | undefined;
    }) => void
  ) => void;
}

const Context = createContext<ContextType>({
  url: undefined,
  data: undefined,
  playing: false,
  addProgressListener: (callback) => {},
  removeProgressListener: (callback) => {},
  play: () => {},
  pause: () => {},
  setUrl: (url) => {},
  setData: (data) => {},
  seekTo: (amount: number, type?: "seconds" | "fraction" | undefined) => {},
});

export interface PlayerData {
  id?: string;
  title?: string;
  subline?: string;
  image?: string;
}

const Provider = ({ children }: any) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [data, setData] = useState<PlayerData | undefined>(undefined);
  const [playing, setPlaying] = useState(false);
  const [position, setPosition] = useState<number | null>(null);

  const setPayPopupOpen = useStore((state) => state.setPayPopupOpen);

  const playerRef = useRef<ReactPlayer>(null);
  const playedRef = useRef<number>(0);
  const playedSecondsRef = useRef<number>(0);

  const progressListeners = useRef<
    Array<
      (state: {
        played: number;
        playedSeconds: number;
        loaded: number;
        loadedSeconds: number;
        url: string | undefined;
      }) => void
    >
  >([]);

  const addProgressListener = (
    callback: (state: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
      url: string | undefined;
    }) => void
  ) => {
    progressListeners.current.push(callback);
  };

  const removeProgressListener = (
    callback: (state: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
      url: string | undefined;
    }) => void
  ) => {
    progressListeners.current = progressListeners.current.filter((listener) => {
      return listener !== callback;
    });
  };

  const onProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    for (const progressListener of progressListeners.current) {
      progressListener({
        ...state,
        url,
      });
    }
    // track played
    if (playedRef.current < 0.1 && state.played >= 0.1) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.1 });
    }
    if (playedRef.current < 0.2 && state.played >= 0.2) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.2 });
    }
    if (playedRef.current < 0.3 && state.played >= 0.3) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.3 });
    }
    if (playedRef.current < 0.4 && state.played >= 0.4) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.4 });
    }
    if (playedRef.current < 0.5 && state.played >= 0.5) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.5 });
    }
    if (playedRef.current < 0.6 && state.played >= 0.6) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.6 });
    }
    if (playedRef.current < 0.7 && state.played >= 0.7) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.7 });
    }
    if (playedRef.current < 0.8 && state.played >= 0.8) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.8 });
    }
    if (playedRef.current < 0.9 && state.played >= 0.9) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 0.9 });
    }
    if (playedRef.current < 1.0 && state.played >= 1.0) {
      mixpanel.track("Played Audio Guide", { id: data?.id, played: 1.0 });
    }

    // track played seconds
    const step = 10;
    const rest = state.playedSeconds % step;
    const lastStep = Math.round(state.playedSeconds - rest);
    if (
      playedSecondsRef.current < lastStep &&
      state.playedSeconds >= lastStep
    ) {
      mixpanel.track("Played seconds of Audio Guide", {
        id: data?.id,
        playedSeconds: lastStep,
      });
    }

    playedRef.current = state.played;
    playedSecondsRef.current = state.playedSeconds;
  };

  const play = () => {
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
  };

  const seekTo = (
    amount: number,
    type?: "seconds" | "fraction" | undefined
  ) => {
    setPosition(amount);
  };

  const exposed = {
    url,
    playing,
    addProgressListener,
    play,
    pause,
    setUrl,
    data,
    setData,
    seekTo,
    removeProgressListener,
  };

  const onPlay = () => {
    if (position != null) {
      playerRef.current?.seekTo(position, "seconds");
      setPosition(null);
    }
    mixpanel.track("Started Audio Guide", {
      id: data?.id,
      played: playedRef.current,
    });
    console.log(`Played at ${playedRef.current}`);
  };

  const onPause = () => {
    mixpanel.track("Paused Audio Guide", {
      id: data?.id,
      played: playedRef.current,
    });
    console.log(`Paused at ${playedRef.current}`);
  };

  const onEnded = () => {
    mixpanel.track("Finished Audio Guide", { id: data?.id });    
    playedRef.current = 0;
  };

  return (
    <Context.Provider value={exposed}>
      <div className="hidden">
        <ReactPlayer
          height={"0%"}
          width={"0%"}
          ref={playerRef}
          style={{ display: "none" }}
          url={url}
          playing={playing}
          onProgress={onProgress}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
        />
      </div>
      {children}
    </Context.Provider>
  );
};

export const useAudioPlayer = () => useContext(Context);

export default Provider;

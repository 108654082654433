import jsonp from "jsonp";
import { useState } from "react";

function buildQueryString(object: any) {
  let arr = [];
  for (const key of Object.keys(object)) {
    if (object[key] != null) {
      arr.push(`${key}=${object[key]}`);
    }
  }
  return arr.join("&");
}

export function useMailchimp(url: string) {
  const jsonURL = url.replace("/post?", "/post-json?");

  const [status, setStatus] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const subscribe = ({ firstName, lastName, email }: any, onSuccess?: () => void) => {
    const queryString = buildQueryString({
      FNAME: firstName,
      LNAME: lastName,
      EMAIL: email,
    });
    const fullURL = `${jsonURL}&${queryString}`;
    setStatus("sending");
    setMessage(null);
    jsonp(
      fullURL,
      {
        param: "c",
      },
      (err, data) => {
        if (err != null) {
          setStatus("error");
          setMessage(err.message);
        } else if (data.result !== "success") {
          setStatus("error");
          setMessage(data.msg);
        } else {
          setStatus("success");
          setMessage(data.msg);
          onSuccess?.()
        }
      }
    );
  };

  return {
    status,
    message,
    subscribe,
  };
}

import { useEffect, useRef } from "react";
import { useXRExtras } from "../context/XRExtrasContext";

export function XRImageTarget({ children, name, onImageFound, onImageLost, delay = 0 }) {
  const {
    addImageFoundListener,
    removeImageFoundListener,
    addImageUpdatedListener,
    removeImageUpdatedListener,
    addImageLostListener,
    removeImageLostListener
  } = useXRExtras();
  const groupRef = useRef();

  const shouldTrack = useRef(false)
  const shouldTrackTimeout = useRef()

  const onImageFoundHandler = (detail) => {
    if (detail.name === name) {
      shouldTrackTimeout.current = setTimeout(() => {
        shouldTrack.current = true;
      }, delay)
      onImageFound?.(detail);
    }
  }

  const onImageUpdatedHandler = (detail) => {
    if (detail.name === name && shouldTrack.current) {
      if (groupRef.current != null) {
        groupRef.current.visible = true;
        groupRef.current.position.copy(detail.position);
        groupRef.current.quaternion.copy(detail.rotation);
        groupRef.current.scale.set(detail.scale, detail.scale, detail.scale);
      }
    }
  }

  const onImageLostHandler = (detail) => {
    if (detail.name === name) {
      if (groupRef.current != null) {
        groupRef.current.visible = false;
      }
      shouldTrack.current = false;
      clearTimeout(shouldTrackTimeout.current)
      onImageLost?.(detail)
    }
  }

  useEffect(() => {
    addImageFoundListener(onImageFoundHandler);
    addImageUpdatedListener(onImageUpdatedHandler);
    addImageLostListener(onImageLostHandler);
    return () => {
      removeImageFoundListener(onImageFoundHandler)
      removeImageUpdatedListener(onImageUpdatedHandler)
      removeImageLostListener(onImageLostHandler)
    }
  }, [name]);

  return (
    <group ref={groupRef} visible={true} scale={[0, 0, 0]}>
      {children}
    </group>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./general-sans.css";
import reportWebVitals from "./reportWebVitals";
import { Router } from "./Router";
import AudioContext from "./context/AudioContext";
import { PlausibleProvider } from "./context/PlausibleContext";
import { MixpanelProvider } from "./context/MixpanelContext";
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MixpanelProvider debug token="9e88c0e0ed301d653809db54217cd530">
    <AudioContext>
      <Router />
    </AudioContext>
  </MixpanelProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import mixpanel from "mixpanel-browser";
import { CheckCircle } from "phosphor-react";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PayPalLogo from "../assets/images/paypal.png";
import { useLocalStorage } from "../hooks/useLocalStorage";

function getRandomPrice() {
  const prices = [0.99, 1.99, 2.99];
  return prices[Math.floor(Math.random() * prices.length)];
}

interface PayPopup {
  onClose: () => void;
  onUnlock: () => void;
}

export function PayPopup({ onClose, onUnlock }: PayPopup) {
  const [price] = useLocalStorage("price-mvp4", getRandomPrice());

  const [luckyPageVisible, setLuckyPageVisible] = useState(false);
  const [feedbackPageVisible, setFeedbackPageVisible] = useState(false);

  const [feedback, setFeedback] = useState("");

  const sendLogsnagNotification = ({ price }: any) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer c1516ef5332f41975e21fe668ef9ce59"
    );

    const raw = JSON.stringify({
      project: "oneguide-mvp",
      channel: "user-payed",
      event: "New Payment",
      description: `Received ${price}€`,
      icon: "💸",
      notify: true,
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.logsnag.com/v1/log", requestOptions);
  };

  const unlockContent = (source: string) => {
    onUnlock();
    setLuckyPageVisible(true);
    mixpanel.track("Payed", {
      source,
      price,
    });
    mixpanel.identify();
    mixpanel.people.track_charge(price);
    sendLogsnagNotification({ price });
  };

  const submitFeedback = () => {
    onUnlock();
    mixpanel.track("Feedback after Pay Popup", {
      feedback,
      price,
    });
    onClose();
  };

  const cancelPayment = () => {
    mixpanel.track("Cancel Payment", {
      price,
    });
    onClose();
  };

  useEffect(() => {
    mixpanel.track("Opened Pay Popup", {
      price,
    });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="fixed inset-0 backdrop-blur-md bg-black/60 z-50 p-3 flex justify-center items-center">
      <div className="w-full max-w-xl mx-auto relative bg-white shadow-lg rounded-xl p-5 pt-20">
        <h1 className="absolute top-4 left-6 text-black text-xl text-center">
          <span className="font-semibold">one</span>Guide
        </h1>

        {!luckyPageVisible && !feedbackPageVisible && (
          <>
            <h2 className="text-3xl text-center">
              <Trans i18nKey="PAY_TITLE">
                Get full access to all
                <br />
                <span className="font-semibold uppercase">
                  East Side Gallery
                </span>
                <br />
                experiences!
              </Trans>
            </h2>

            <div className="mt-10 space-y-3 flex flex-col items-start px-10">
              <div className="flex flex-row justify-center items-center space-x-3 text-2xl">
                <CheckCircle weight="fill" className="fill-cyan-500" />
                <span className="text-lg font-medium">6 Audio Stories</span>
              </div>
              <div className="flex flex-row justify-center items-center space-x-3 text-2xl">
                <CheckCircle weight="fill" className="fill-cyan-500" />
                <span className="text-lg font-medium">1 AR Experience</span>
              </div>
            </div>

            <div className="mt-8 flex flex-row items-end justify-center">
              <span className="text-7xl font-semibold">
                {price.toLocaleString()}
              </span>
              <span className="text-3xl">€</span>
            </div>

            <div className="mt-12 flex flex-col items-center px-6">
              <button
                onClick={() => unlockContent("Pay with PayPal")}
                className="bg-cyan-500 text-white rounded-xl w-full py-3 font-medium text-lg flex justify-center items-center"
              >
                <span className="leading-none">{t("PAY_BUTTON")}</span>{" "}
                <img
                  src={PayPalLogo}
                  alt="paypal"
                  className="ml-2 h-5 mt-0.5"
                />
              </button>
              <button
                onClick={() => unlockContent("Other Payment Method")}
                className="mt-2 bg-slate-200 text-slate-500 rounded-xl w-full py-2 font-medium text-xs"
              >
                {t("PAY_OTHER_METHOD_BUTTON")}
              </button>
              <div
                onClick={cancelPayment}
                className="mt-3 font-medium text-slate-500"
              >
                {t("PAY_CANCEL")}
              </div>
            </div>
          </>
        )}

        {luckyPageVisible && (
          <div className="mt-12">
            <h3 className="text-center text-cyan-500 font-semibold">
              {t("PAY_LUCKY")}
            </h3>
            <h2 className="text-2xl text-center">
              <Trans i18nKey="PAY_LUCKY_TITLE">
                You don’t need to pay anything. Enjoy your free
                <span className="font-semibold uppercase">
                  {" "}
                  East Side Gallery{" "}
                </span>
                tour!
              </Trans>
            </h2>

            <div className="mt-20 mb-20 flex flex-col items-center px-8">
              <button
                onClick={onClose}
                className="bg-cyan-500 text-white rounded-xl w-full py-3 font-medium text-lg flex justify-center items-center"
              >
                {t("PAY_LUCKY_BUTTON")}
              </button>
            </div>
          </div>
        )}

        {feedbackPageVisible && (
          <div className="">
            <h3 className="text-center text-cyan-500 font-semibold">
              {t("PAY_FEEDBACK")}
            </h3>
            <h2 className="text-2xl text-center px-4">
              {t("PAY_FEEDBACK_TITLE")}
            </h2>
            <h2 className="mt-8 text-xl text-left px-2">
              <Trans i18nKey="PAY_FEEDBACK_CTA">
                Get <span className="font-semibold">free access</span> by
                submitting your feedback:
              </Trans>
            </h2>

            <div className="mt-4 mb-8 flex flex-col items-center px-2 space-y-4">
              <textarea
                value={feedback}
                onChange={(evt) => setFeedback(evt.target.value)}
                placeholder="Feedback"
                className="w-full rounded-lg border border-gray-400 p-2"
                rows={8}
              ></textarea>
              <button
                disabled={feedback.trim().length === 0}
                onClick={submitFeedback}
                className="bg-cyan-500 disabled:bg-cyan-500/60 text-white rounded-xl w-full py-3 font-medium text-lg flex justify-center items-center"
              >
                {t("PAY_FEEDBACK_SUBMIT")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

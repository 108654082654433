import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BottomAudioPlayer } from "./components/BottomAudioPlayer";
import { useAudioPlayer } from "./context/AudioContext";
import i18n from "./i18n";
import { ARTestTheBest } from "./pages/ARTestTheBest";
import { ESG } from "./pages/ESG";
import { Home } from "./pages/Home";
import { ImprintPage } from "./pages/Imprint";

export function Router() {
  const { url, data } = useAudioPlayer();

  useEffect(() => {
    mixpanel.register({
      app_language: i18n.resolvedLanguage,
    });
  }, [i18n.resolvedLanguage]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ESG />} />
        <Route path="/ar-test-the-best" element={<ARTestTheBest />} />
        <Route path="/impressum" element={<ImprintPage />} />
      </Routes>
      {url != null && data != null && <BottomAudioPlayer />}
    </BrowserRouter>
  );
}

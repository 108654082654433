import * as THREE from "three";
import { MeshBasicMaterial } from "three";
import { MeshStandardMaterial } from "three";
import { BoxGeometry } from "three";
import { Mesh } from "three";

export const customThreejsPipelineModule = (scene3) => {
  let engaged = false;

  const engage = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
    if (engaged) {
      return;
    }
    const { scene, renderer, camera } = scene3;
    // const scene = new THREE.Scene()
    // const camera = new THREE.PerspectiveCamera(
    //   60.0, /* initial field of view; will get set based on device info later. */
    //   canvasWidth / canvasHeight,
    //   0.01,
    //   1000.0,
    // )
    // scene.add(camera)

    // const renderer = new THREE.WebGLRenderer({
    //   canvas,
    //   context: GLctx,
    //   alpha: false,
    //   antialias: true,
    // })
    renderer.autoClear = false;
    renderer.alpha = false;
    // renderer.setSize(canvasWidth, canvasHeight)
    //renderer.clear();

    // const mesh = new Mesh(
    //     new BoxGeometry(1, 1),
    //     new MeshBasicMaterial({
    //       color: "red",
    //     })
    //   )

    //   mesh.position.set(0, -5, 0)

    //   scene.add(mesh)

    scene3 = { scene, camera, renderer };
    engaged = true;
  };

  // This is a workaround for https://bugs.webkit.org/show_bug.cgi?id=237230
  // Once the fix is released, we can add `&& parseFloat(device.osVersion) < 15.x`
  const device = window.XR8.XrDevice.deviceEstimate();
  const needsPrerenderFinish =
    device.os === "iOS" && parseFloat(device.osVersion) >= 15.4;

  return {
    name: "customthreejs",
    onStart: (args) => engage(args),
    onAttach: (args) => engage(args),
    onDetach: () => {
      engaged = false;
    },
    onUpdate: ({ processCpuResult }) => {
      const realitySource =
        processCpuResult.reality || processCpuResult.facecontroller;

      if (!realitySource) {
        return;
      }

      const { rotation, position, intrinsics } = realitySource;
      const { camera } = scene3;

      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i];
      }

      // Fix for broken raycasting in r103 and higher. Related to:
      //   https://github.com/mrdoob/three.js/pull/15996
      // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
      // the inverse
      if (camera.projectionMatrixInverse) {
        if (camera.projectionMatrixInverse.invert) {
          // THREE 123 preferred version
          camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
        } else {
          // Backwards compatible version
          camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
        }
      }

      if (rotation) {
        camera.setRotationFromQuaternion(rotation);
      }
      if (position) {
        camera.position.set(position.x, position.y, position.z);
      }
    },
    onRender: () => {
      const { scene, renderer, camera } = scene3;
      renderer.clearDepth();
      if (needsPrerenderFinish) {
        renderer.getContext().finish();
      }
      renderer.render(scene, camera);
    },
    // Get a handle to the xr scene, camera and renderer. Returns:
    // {
    //   scene: The Threejs scene.
    //   camera: The Threejs main camera.
    //   renderer: The Threejs renderer.
    // }
    xrScene: () => {
      return scene3;
    },
  };
};

import { Pause, Play } from "phosphor-react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAudioPlayer } from "../context/AudioContext";

export function BottomAudioPlayer() {
  const { play, pause, playing, data, addProgressListener } =
    useAudioPlayer();
  const navigate = useNavigate();

  const progressBar = useRef<HTMLDivElement | null>(null);

  const onProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {    
    if (progressBar.current) {
      progressBar.current.style.width = `${state.played * 100}%`
    }

  };
  useEffect(() => {
    addProgressListener(onProgress);
  }, []);

  return (
    <div className="z-20 fixed bottom-1 left-1 right-1">
      <div className="bg-black relative rounded-xl shadow-lg w-full max-w-xl mx-auto py-2 px-2 flex flex-row justify-between items-center overflow-hidden">
        <div className="flex flex-row justify-center items-center space-x-3 mb-0.5">
          <div
            style={{
              backgroundImage: `url(${data?.image})`,
            }}
            className="bg-cover bg-center h-10 w-10 rounded-md cursor-pointer"
          ></div>
          <div className="flex flex-col">
            <span className="text-white font-medium text-xs">{data?.title}</span>
            <span className="font-medium text-xs text-gray-400">
              {data?.subline}
            </span>
          </div>
        </div>
        <div className="mr-4 cursor-pointer">
          {playing ? (
            <Pause
              onClick={() => pause()}
              weight="fill"
              color="white"
              className="h-6 w-6"
            />
          ) : (
            <Play
              onClick={() => play()}
              weight="fill"
              color="white"
              className="h-6 w-6"
            />
          )}
        </div>
        <div className="absolute bg-gray-600 h-1 bottom-0 left-2 right-2">
          <div
            ref={progressBar}
            className="bg-cyan-500 absolute bottom-0 top-0 left-0 transition-all duration-500"
          ></div>
        </div>
      </div>
    </div>
  );
}

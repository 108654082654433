import { useMailchimp } from "../hooks/useMailchimp";
import { useState } from "react";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

export function MoreToursPopup() {
  const [email, setEmail] = useState<string>();

  const { status, message, subscribe } = useMailchimp(
    "https://one-guide.us13.list-manage.com/subscribe/post?u=ea4d8ef6f07df12ba6b216355&amp;id=ca4a926897"
  );

  const { t } = useTranslation();

  return (
    <div className="absolute inset-6 top-20">
      <>
        <h1 className="mt-6 text-white font-semibold text-2xl">
          {t("NEWSLETTER_HEADLINE")}
        </h1>
        <h2 className="mt-6 text-white font-medium text-xl">
          {t("NEWSLETTER_SUBLINE")}
        </h2>
        <div className="mt-8">
          {status === "sending" && (
            <div className="text-white my-2">{t("NEWSLETTER_LOADING")}</div>
          )}
          {status === "error" && (
            <div className="text-red-400 my-2">
              {t("NEWSLETTER_INVALID_EMAIL")}
            </div>
          )}
          {status !== "success" && (
            <div>
              <input
                className=" text-white bg-transparent px-4 py-3 w-full border-2 border-gray-600/50 rounded-lg"
                type="email"
                placeholder={t("NEWSLETTER_EMAIL_INPUT_PLACEHOLDER")}
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => {
                    subscribe({ email: email }, () => {
                      mixpanel.track("Subscribed to newsletter", {
                        email: email,
                        origin: "More Tours",
                      });
                      mixpanel.identify();
                      mixpanel.people.set({
                        email,
                      });
                    });
                  }}
                  className="bg-cyan-500 px-8 py-2 text-white text-md font-medium rounded-lg"
                >
                  {t("NEWSLETTER_SUBSCRIBE_BUTTON_TEXT")}
                </button>
              </div>
            </div>
          )}
          {status === "success" && (
            <div className="text-green-500 text-xl font-semibold">
              {t("NEWSLETTER_SUCCESS")}
            </div>
          )}
        </div>
      </>
    </div>
  );
}

import { Html, OrbitControls } from "@react-three/drei";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import MarkerImage from "../assets/images/test-the-rest.jpg"
import { WallMask } from "./Mask";
import { XRImageTarget } from "./XRImageTarget";
import { useTranslation } from "react-i18next";

export function Scene(props) {

  const [found, setFound] = useState(false)
  const [playAnimation, setPlayAnimation] = useState(false)

  useEffect(() => {
    let timeout;
    if (found) {
      timeout = setTimeout(() => {
        setPlayAnimation(true)
      }, 500)
    } else {
      setPlayAnimation(false)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [found])

  const { t } = useTranslation();

  return (
    <group>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls />
      {/* <Box position={[0, -5, 0]} scale={[1, 1, 1]}>
        <meshBasicMaterial color="red" />
      </Box> */}
      <XRImageTarget name="trabbi-marker" onImageFound={() => {
        setFound(true)
        mixpanel.track("Detected Image Marker", { marker: "trabbi-marker" })
      }} onImageLost={() => setFound(false)} delay={500}>
        <WallMask
          active={playAnimation}
          rotation={[0, 0, 0]}
          position={[-0.075, -0.05, 0]}
          scale={[0.25, 0.25, 0.25]}
        />
      </XRImageTarget>

      <Html fullscreen zIndexRange={[49, 0]}>
        <div className={classNames("flex flex-col h-full justify-center items-center transition-opacity duration-500", { "opacity-0": found })}>
          <img className="w-3/4 opacity-70 max-w-lg" src={MarkerImage} alt="Test the rest Marker" />
          <h1 className="text-center font-medium text-2xl text-white mt-10">
            {t("ESG_TEST_THE_BEST_AR_SCAN_COACHING_TEXT")}<br /><span className="font-semibold">TEST THE REST</span>
          </h1>
        </div>
      </Html>

      {props?.children}
    </group>
  );
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function TextCollapse({ children, preview, onShow }: any) {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (!collapsed) {
      onShow?.();
    }
  }, [collapsed]);

  const { t } = useTranslation();

  return (
    <>
      {collapsed ? (
        <>
          {preview}{" "}
          <span
            className="font-medium text-cyan-600 underline cursor-pointer"
            onClick={() => setCollapsed(false)}
          >
            {t("CONTINUE_READING")}
          </span>
        </>
      ) : (
        <>
          {children}{" "}
          <span
            className="font-medium text-cyan-600 underline cursor-pointer"
            onClick={() => setCollapsed(true)}
          >
            {t("SHOW_LESS")}
          </span>
        </>
      )}
    </>
  );
}

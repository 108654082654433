import { useFrame, useThree } from "@react-three/fiber";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { customThreejsPipelineModule } from "../components/customPipeline";
import { imageTargetPipelineModule } from "../components/imageTargetModule";

const Context = createContext({
  loaded: false,
  scene: null,
  camera: null,
  addImageFoundListener: () => {},
  removeImageFoundListener: () => {},
  addImageUpdatedListener: () => {},
  removeImageUpdatedListener: () => {},
  addImageLostListener: () => {},
  removeImageLostListener: () => {},
});

function Provider({ children }) {

  const [loaded, setLoaded] = useState(false);

  const { camera, gl, scene } = useThree();

  const [imageFoundListeners, setImageFoundListeners] = useState([]);
  const [imageUpdatedListeners, setImageUpdatedListeners] = useState([]);
  const [imageLostListeners, setImageLostListeners] = useState([]);

  const onImageFound = useCallback(
    (details) => {
      imageFoundListeners.forEach((cb) => {
        cb(details);
      });
    },
    [imageFoundListeners]
  );

  const onImageUpdated = useCallback(
    (details) => {
      imageUpdatedListeners.forEach((cb) => {
        cb(details);
      });
    },
    [imageUpdatedListeners]
  );

  const onImageLost = useCallback(
    (details) => {
      imageLostListeners.forEach((cb) => {
        cb(details);
      });
    },
    [imageLostListeners]
  );

  const addImageFoundListener = (cb) => {
    setImageFoundListeners((listeners) => {
      return [...listeners, cb];
    });
  };
  
  const removeImageFoundListener = (cb) => {
    setImageFoundListeners((listeners) => {
      return listeners.filter((listener) => {
        return listener !== cb
      });
    });
  };

  const addImageUpdatedListener = (cb) => {
    setImageUpdatedListeners((listeners) => {
      return [...listeners, cb];
    });
  };

  const removeImageUpdatedListener = (cb) => {
    setImageUpdatedListeners((listeners) => {
      return listeners.filter((listener) => {
        return listener !== cb
      });
    });
  };

  const addImageLostListener = (cb) => {
    setImageLostListeners((listeners) => {
      return [...listeners, cb];
    });
  };

  const removeImageLostListener = (cb) => {
    setImageLostListeners((listeners) => {
      return listeners.filter((listener) => {
        return listener !== cb
      });
    });
  };

  useEffect(() => {
    if (loaded) {
      window.XR8.XrController.configure({ disableWorldTracking: true });
      const threejs = customThreejsPipelineModule({
        camera,
        renderer: gl,
        scene,
      });
      const imageTarget = imageTargetPipelineModule({
        camera,
        onImageFound,
        onImageUpdated,
        onImageLost,
      });

      window.XR8.addCameraPipelineModules([

        window.XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
        threejs,
        window.XR8.XrController.pipelineModule(), // Enables SLAM tracking.
        window.XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
        window.XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
        window.XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
        imageTarget,
      ]);
      // Open the camera and start running the camera run loop.
      window.XR8.run({ canvas: gl.domElement });
    }
  }, [loaded]);

  useEffect(() => {
    // Show loading screen before the full XR library has been loaded.
    const load = () => {
      console.log("LOAD");
      window.XRExtras.Loading.showLoading({
        onxrloaded: () => {
          setLoaded(true);
        },
      });
    };
    window.XRExtras ? load() : window.addEventListener("xrextrasloaded", load);
    return () => {
      if (window.XR8) {
        window.XR8.stop()
        window.XRExtras.AlmostThere.hideAlmostThere();
      }
    }
  }, []);

  // disable r3f renderer
  useFrame(({ gl, scene, camera }) => {}, 1);

  const exposed = {
    loaded,
    addImageFoundListener,
    removeImageFoundListener,
    addImageUpdatedListener,
    removeImageUpdatedListener,
    addImageLostListener,
    removeImageLostListener
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
}

export const useXRExtras = () => useContext(Context);

export default Provider;

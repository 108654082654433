import { Mask, Plane, useGLTF, useMask, useTexture } from "@react-three/drei";
import { forwardRef, useState } from "react";
import { useSpring, animated } from "@react-spring/three";
import MaskModel from "../assets/models/test-the-best.glb";
import TrabbiBackground from "../assets/images/trabbi_cleanup.jpg";
import AlphaMap from "../assets/images/AlphaMap.jpg";

const ANIMATION_DURATION = 1200;

export const WallMask = forwardRef(({ active, ...props }, ref) => {
  const { nodes, materials } = useGLTF(MaskModel);
  const stencil = useMask(1);
  const stencilInv = useMask(1, true);

  // Stone 1
  const stone1Animation = useSpring({
    position: active ? [1.21, 1.18, 2.1] : [0.59, 1.06, 1.5],
    rotation: active ? [0.15, 0.16, 0.4] : [-0.08, 0.42, 0.64],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });
  // Stone 2
  const stone2Animation = useSpring({
    position: active ? [0.57, 1.06, 1.42] : [0.37, 0.49, 0.61],
    rotation: active ? [-0.03, 0.52, 0.34] : [-0.08, 0.92, 0.14],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });
  // Stone 3
  const stone3Animation = useSpring({
    position: active ? [0.42, -0.11, 1.15] : [0.33, -0.41, 0.67],
    rotation: active ? [-0.13, 0.15, 0.17] : [-0.58, 1.92, 0.94],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });
  // Stone 4
  const stone4Animation = useSpring({
    position: active ? [1.49, 0.37, -1.61] : [0.43, -0.11, -1.1],
    rotation: active ? [0, 0.19, 0.16] : [-0.38, 1.92, -0.94],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  // Stone 5
  const stone5Animation = useSpring({
    position: active ? [1, 0.62, -2.3] : [0.36, 0.86, -1.2],
    rotation: active ? [-0.9, -0.07, 0.37] : [-1.38, -0.92, 0.94],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  // Stone 6
  const stone6Animation = useSpring({
    position: active ? [1.23, -1.14, 1.02] : [0.48, -1.2, 0.11],
    rotation: active ? [0, 0, 0.97] : [0, 0, 1.8],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  // Stone 7
  const stone7Animation = useSpring({
    position: active ? [2.13, -0.84, -1.22] : [0.79, -0.94, -1.02],
    rotation: active ? [0, -0.02, 0.25] : [2.3, -0.9, 0.25],
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  // Trabant Animation
  const trabantAnimation = useSpring({
    position: active ? [-0.31, 0.06, -0.32] : [-1.98, 0.06, -0.32],
    rotation: active
      ? [Math.PI / 2, -0.07, Math.PI]
      : [Math.PI / 2, 0.03, Math.PI],
    config: {
      duration: ANIMATION_DURATION + 500,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  const opacityAnimation = useSpring({
    opacity: active ? 1 : 0,
    config: {
      duration: ANIMATION_DURATION / 2,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });
  const slowOpacityAnimation = useSpring({
    opacity: active ? 1 : 0,
    config: {
      duration: ANIMATION_DURATION,
      easing: (x) => 1 - (1 - x) * (1 - x),
    },
  });

  const texture = useTexture(TrabbiBackground);
  const alphaMap = useTexture(AlphaMap);

  return (
    <group ref={ref} {...props} dispose={null}>
      <Plane receiveShadow args={[5, 5]}>
        <animated.meshBasicMaterial
          map={texture}
          fog={false}
          alphaMap={alphaMap}
          transparent
          opacity={slowOpacityAnimation.opacity}
          {...stencilInv}
        />
      </Plane>
      <group rotation={[0, -Math.PI / 2, 0]}>
        <Mask
          id={1}
          geometry={nodes.Mask.geometry}
          rotation={[0, 0, -Math.PI / 2]}
        />

        <mesh
          name="BlackVoid"
          geometry={nodes.BlackVoid.geometry}
          position={[0.3, 0, 0]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={1.36}
        >
          <meshBasicMaterial color="#295266" {...stencil} />
        </mesh>
        <animated.mesh
          name="BrickInner"
          geometry={nodes.BrickInner.geometry}
          material={materials.BrickInner}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <animated.mesh
          name="Stone2"
          geometry={nodes.Stone2.geometry}
          position={stone2Animation.position}
          rotation={stone2Animation.rotation}
          scale={1.31}
        >
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone1"
          geometry={nodes.Stone1.geometry}
          position={stone1Animation.position}
          rotation={stone2Animation.rotation}
          scale={1.18}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone3"
          geometry={nodes.Stone3.geometry}
          position={stone3Animation.position}
          rotation={stone3Animation.rotation}
          scale={1.17}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone4"
          geometry={nodes.Stone4.geometry}
          position={stone4Animation.position}
          rotation={stone4Animation.rotation}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone5"
          geometry={nodes.Stone5.geometry}
          position={stone5Animation.position}
          rotation={stone5Animation.rotation}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone6"
          geometry={nodes.Stone6.geometry}
          position={stone6Animation.position}
          rotation={stone6Animation.rotation}
          scale={1.38}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.mesh
          name="Stone7"
          geometry={nodes.Stone7.geometry}
          position={stone7Animation.position}
          rotation={stone7Animation.rotation}
          scale={1.33}
        >
          {" "}
          <animated.meshStandardMaterial
            {...materials.Stone_Birgit}
            transparent
            opacity={opacityAnimation.opacity}
          />
        </animated.mesh>
        <animated.group
          name="Trabant"
          position={trabantAnimation.position}
          rotation={trabantAnimation.rotation}
          scale={0.13}
        >
          <mesh
            name="Trabant_1"
            geometry={nodes.Trabant_1.geometry}
            material={materials.CarPaint}
          />
          <mesh
            name="Trabant_2"
            geometry={nodes.Trabant_2.geometry}
            material={materials.Window}
          />
          <mesh
            name="Trabant_3"
            geometry={nodes.Trabant_3.geometry}
            material={materials.Metal}
          />
          <mesh
            name="Trabant_4"
            geometry={nodes.Trabant_4.geometry}
            material={materials.BlackPaint}
          />
          <mesh
            name="Trabant_5"
            geometry={nodes.Trabant_5.geometry}
            material={materials.OrangeLight}
          />
          <mesh
            name="Trabant_6"
            geometry={nodes.Trabant_6.geometry}
            material={materials.Roof}
          />
          <group
            name="License"
            position={[-16.46, -0.03, 3.12]}
            rotation={[Math.PI / 2, 0, 1.57]}
            scale={10.45}
          >
            <mesh
              name="Plane002"
              geometry={nodes.Plane002.geometry}
              material={materials.LicensePlate}
            />
            <mesh
              name="Plane002_1"
              geometry={nodes.Plane002_1.geometry}
              material={materials.LicensePlateOuter}
            />
          </group>
          <mesh
            name="LightRight"
            geometry={nodes.LightRight.geometry}
            material={materials.WhiteLight}
            position={[0.07, 0, 0]}
          />
          <mesh
            name="LightLeft"
            geometry={nodes.LightLeft.geometry}
            material={materials.WhiteLight}
            position={[0.07, 0, 0]}
          />
          <group
            name="Wheel_FL"
            position={[-11.1, 5.34, 3.12]}
            rotation={[Math.PI, 0, Math.PI / 2]}
            scale={[8.6, 6.84, 6.84]}
          >
            <mesh
              name="Wheel_FL_Wheel_0002"
              geometry={nodes.Wheel_FL_Wheel_0002.geometry}
              material={materials.Wheel}
            />
            <mesh
              name="Wheel_FL_Wheel_0002_1"
              geometry={nodes.Wheel_FL_Wheel_0002_1.geometry}
              material={materials.Metal}
            />
          </group>
          <group
            name="Wheel_FR"
            position={[-11.1, -5.32, 3.12]}
            rotation={[Math.PI, 0, -Math.PI / 2]}
            scale={[8.6, 6.84, 6.84]}
          >
            <mesh
              name="Wheel_FL_Wheel_0001"
              geometry={nodes.Wheel_FL_Wheel_0001.geometry}
              material={materials.Wheel}
            />
            <mesh
              name="Wheel_FL_Wheel_0001_1"
              geometry={nodes.Wheel_FL_Wheel_0001_1.geometry}
              material={materials.Metal}
            />
          </group>
          <group
            name="Wheel_RL"
            position={[6.27, 5.34, 3.21]}
            rotation={[Math.PI, 0, Math.PI / 2]}
            scale={[8.6, 6.84, 6.84]}
          >
            <mesh
              name="Wheel_FL_Wheel_0004"
              geometry={nodes.Wheel_FL_Wheel_0004.geometry}
              material={materials.Wheel}
            />
            <mesh
              name="Wheel_FL_Wheel_0004_1"
              geometry={nodes.Wheel_FL_Wheel_0004_1.geometry}
              material={materials.Metal}
            />
          </group>
          <mesh
            name="Logo"
            geometry={nodes.Logo.geometry}
            material={materials.BlackPaint}
            position={[-15.39, 0, -0.73]}
            rotation={[0.15, -0.95, 1.7]}
            scale={10.3}
          />
          <group
            name="Wheel_RR"
            position={[6.27, -5.32, 3.21]}
            rotation={[Math.PI, 0, -Math.PI / 2]}
            scale={[8.6, 6.84, 6.84]}
          >
            <mesh
              name="Wheel_FL_Wheel_0003"
              geometry={nodes.Wheel_FL_Wheel_0003.geometry}
              material={materials.Wheel}
            />
            <mesh
              name="Wheel_FL_Wheel_0003_1"
              geometry={nodes.Wheel_FL_Wheel_0003_1.geometry}
              material={materials.Metal}
            />
          </group>
          <mesh
            name="grill"
            geometry={nodes.grill.geometry}
            material={materials.Metal}
            position={[-15.61, 0, 1.31]}
          />
        </animated.group>
      </group>
    </group>
  );
});

useGLTF.preload(MaskModel);

import { Canvas } from "@react-three/fiber";
import mixpanel from "mixpanel-browser";
import { CaretLeft } from "phosphor-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Scene } from "../components/Scene";
import XRExtrasContext, { useXRExtras } from "../context/XRExtrasContext";

export function ARTestTheBest() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    mixpanel.track("Opened page", { path: pathname });
  }, [pathname]);

  return (
    <>
      <div
        onClick={() => {
          navigate("/");
        }}
        className="z-50 fixed top-5 left-5 h-10 w-10 p-2 rounded-full bg-black/50"
      >
        <CaretLeft className="h-full w-full" color="white" />
      </div>
      <Canvas>
        <XRExtrasContext>
          <Scene />
        </XRExtrasContext>
      </Canvas>
    </>
  );
}

import { MeshBasicMaterial } from "three";
import { BoxGeometry } from "three";
import { Mesh } from "three";

export const imageTargetPipelineModule = ({
  camera,
  onImageFound,
  onImageUpdated,
  onImageLost,
}) => {
  // Grab a handle to the threejs scene and set the camera position on pipeline startup.
  const onStart = ({ canvas }) => {
    camera.position.set(0, 3, 0);
    // prevent scroll/pinch gestures on canvas
    canvas.addEventListener("touchmove", (event) => {
      event.preventDefault();
    });

    // Sync the xr controller's 6DoF position and camera paremeters with our scene.
    window.XR8.XrController.updateCameraProjectionMatrix({
      origin: camera.position,
      facing: camera.quaternion,
    });
  };

  return {
    // Camera pipeline modules need a name. It can be whatever you want but must be
    // unique within your app.
    name: "image-target",

    // onStart is called once when the camera feed begins. In this case, we need to wait for the
    // XR8.Threejs scene to be ready before we can access it to add content. It was created in
    // XR8.Threejs.pipelineModule()'s onStart method.
    onStart,

    // Listeners are called right after the processing stage that fired them. This guarantees that
    // updates can be applied at an appropriate synchronized point in the rendering cycle.
    listeners: [
      {
        event: "reality.imagefound",
        process: ({ detail }) => onImageFound(detail),
      },
      {
        event: "reality.imageupdated",
        process: ({ detail }) => onImageUpdated(detail),
      },
      {
        event: "reality.imagelost",
        process: ({ detail }) => onImageLost(detail),
      },
    ],
  };
};

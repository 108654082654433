import { useMailchimp } from "../hooks/useMailchimp";

import GermanyFlag from "../assets/images/flags/germany-flag.png";
import UKFlag from "../assets/images/flags/uk-flag.png";
import SpainFlag from "../assets/images/flags/spain-flag.png";
import FranceFlag from "../assets/images/flags/france-flag.png";
import ItalyFlag from "../assets/images/flags/italy-flag.png";
import { useState } from "react";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import { useTranslation } from "react-i18next";

const languages = [
  {
    active: true,
    image: GermanyFlag,
    name: "Deutsch",
    key: "de",
    available: true,
  },
  {
    image: UKFlag,
    name: "English",
    key: "en",
    available: true,
  },
  {
    image: SpainFlag,
    name: "Español",
    key: "es",
    available: false,
  },
  {
    image: FranceFlag,
    name: "Français",
    key: "fr",
    available: false,
  },
  {
    image: ItalyFlag,
    name: "Italiano",
    key: "it",
    available: false,
  },
];

export function LanguageSelectorPopup({ onClose }: { onClose: () => void }) {
  const [newsletterVisible, setNewsletterVisible] = useState(false);

  const [email, setEmail] = useState<string>();

  const { status, message, subscribe } = useMailchimp(
    "https://one-guide.us13.list-manage.com/subscribe/post?u=ea4d8ef6f07df12ba6b216355&amp;id=ca4a926897"
  );

  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="absolute inset-6 top-20">
      {!newsletterVisible && (
        <>
          <h1 className="mb-8 text-white font-semibold text-2xl">
            Select your language:
          </h1>
          <div className="space-y-4">
            {languages.map((language) => {
              return (
                <div
                  key={language.key}
                  onClick={() => {
                    if (i18n.languages.includes(language.key)) {
                      changeLanguage(language.key);
                      onClose();
                    } else {
                      setNewsletterVisible(true);
                    }
                    mixpanel.track("Selected language", {
                      language: language.name,
                    });
                  }}
                  className={classNames(
                    "border-2 border-gray-600/50 rounded-xl flex items-center space-x-4 p-4 hover:bg-white/20 cursor-pointer",
                    { "bg-white/20": language.key === i18n.resolvedLanguage }
                  )}
                >
                  <div
                    className="h-10 w-16 rounded-md shadow-lg bg-cover"
                    style={{
                      backgroundImage: `url(${language.image})`,
                    }}
                  />
                  <span className="text-white text-lg font-semibold">
                    {language.name}
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
      {newsletterVisible && (
        <>
          <h1 className="mt-6 text-white font-semibold text-2xl">
            We are working on adding more languages!
          </h1>
          <h2 className="mt-6 text-white font-medium text-xl">
            Do want to get notified when we support your language?
          </h2>
          <div className="mt-8">
            {status === "sending" && (
              <div className="text-white my-2">Loading...</div>
            )}
            {status === "error" && (
              <div className="text-red-400 my-2">
                This is not an email address!
              </div>
            )}
            {status !== "success" && (
              <div>
                <input
                  className=" text-white bg-transparent px-4 py-3 w-full border-2 border-gray-600/50 rounded-lg"
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => {
                      subscribe({ email: email }, () => {
                        console.log("SUBSCRIBED");

                        mixpanel.track("Subscribed to newsletter", {
                          email: email,
                          origin: "Language Selector",
                        });
                        mixpanel.identify();
                        mixpanel.people.set({
                          email,
                        });
                      });
                    }}
                    className="bg-cyan-500 px-8 py-2 text-white text-md font-medium rounded-lg"
                  >
                    Notify me
                  </button>
                </div>
              </div>
            )}
            {status === "success" && (
              <div className="text-green-500 text-xl font-semibold">
                Congrats! You joined the waiting list!
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

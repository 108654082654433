import mixpanel from "mixpanel-browser";
import { ArrowRight } from "phosphor-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMailchimp } from "../hooks/useMailchimp";

export function NewsletterSignup() {
  const [email, setEmail] = useState<string>();

  const { status, message, subscribe } = useMailchimp(
    "https://one-guide.us13.list-manage.com/subscribe/post?u=ea4d8ef6f07df12ba6b216355&amp;id=ca4a926897"
  );

  const { t } = useTranslation();

  return (
    <div className="bg-cyan-500 rounded-2xl shadow-lg p-6">
      <h1 className="text-[1.6rem] text-white">{t("NEWSLETTER_HEADLINE")}</h1>
      <h2 className="mt-1 text-sm font-semibold text-white">
        {t("NEWSLETTER_SUBLINE")}
      </h2>

      <div className="mt-8">
        {status === "sending" && (
          <div className="text-white my-2">{t("NEWSLETTER_LOADING")}</div>
        )}
        {status === "error" && (
          <div className="text-white my-2">{t("NEWSLETTER_INVALID_EMAIL")}</div>
        )}
        {status !== "success" && (
          <div>
            <input
              className=" text-white bg-transparent px-4 py-3 w-full border-2 border-white rounded-lg placeholder:text-white/80"
              type="email"
              placeholder={t("NEWSLETTER_EMAIL_INPUT_PLACEHOLDER")}
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => {
                  subscribe({ email: email }, () => {
                    console.log("SUBSCRIBED");

                    mixpanel.track("Subscribed to newsletter", {
                      email: email,
                      origin: "East Side Gallery Home Page",
                    });
                    mixpanel.identify();
                    mixpanel.people.set({
                      email,
                    });
                  });
                }}
                className="bg-white px-8 py-2 text-cyan-500 text-md font-medium rounded-lg"
              >
                {t("NEWSLETTER_SUBSCRIBE_BUTTON_TEXT")}
              </button>
            </div>
          </div>
        )}
        {status === "success" && (
          <div className="text-white text-xl font-semibold">
            {t("NEWSLETTER_SUCCESS")}
          </div>
        )}
      </div>
    </div>
  );
}

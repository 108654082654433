import { Padding } from "../components/Padding";
import { Page } from "../components/Page";

export function ImprintPage() {
  return (
    <Page>
      <div className="px-8">
        <div className="mt-6">
          <a href="/">Zurück</a>
        </div>
        <div className="px-4">
          <h1 className="mt-8 text-3xl font-medium">Impressum</h1>
          <p className="mt-8">
            oneGuide GbR
            <br />
            <br />
            Maxstraße 27
            <br />
            22089 Hamburg
            <br />
            <br />
            E-Mail: m.lueckert@me.com
          </p>
          <p className="mt-4">
            <span className="font-medium">Vertreten durch:</span>
            <br />
            Wiebke Nadzeika, Linus Günther und Marlon Lückert
          </p>
          <p className="mt-12">
            <span className="font-medium">Quellen Hinweise:</span>
            <br />
            Musik:{" "}
            <a
              href="https://www.musicfox.com/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.musicfox.com/
            </a>
          </p>
        </div>
      </div>
    </Page>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import de_translation from "./locales/de.json";
import en_translation from "./locales/en.json";

const resources = {
  en: en_translation,
  de: de_translation,
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: ["en", "de", "dev"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
